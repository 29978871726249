@use "../../styles/variables" as v;

.lazy {
  height: 100%;
  width: 100%;

  &__item {
    background-color: rgb(245, 245, 245);
    width: 100%;
    margin: 15px 0px;

    &.header {
      max-width: 100%;
      height: 100px;
    }

    &.paragraph {
      max-width: 90%;
      height: 400px;
    }

    &.short {
      max-width: 25%;
      height: 50px;
    }

    &.long {
      max-width: 75%;
      height: 50px;
    }
  }

  @include v.tablet {
    padding: 0px 15px;
  }
}
