@use "../../styles/variables" as v;

.inner_container.bread {
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
  ul.bread_crumbs {
    padding-left: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;
    border: 1px solid #fbcfe8;
    color: #db2777;
    padding: 4px 18px 4px 8px;
    border-radius: 22px;

    svg {
      position: relative;
      top: 2px;
      color: #db2777;

      &:last-child {
        display: none;
      }
    }
    li {
      list-style-type: none;
      margin-top: 0px;

      a {
        color: #db2777;
      }
    }
  }

  @include v.tablet {
    margin-bottom: 36px;
  }
}
