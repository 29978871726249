@use "../../styles/variables" as v;

.navigation__container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex: 0 0 auto;
  width: calc((100% - 1448px) / 2 + 298px);
  display: flex;
  z-index: 15;
  min-width: 298px;
  // background: #f8fafc;
  align-items: stretch;
  padding-left: calc((100% - 1448px) / 2);
  padding-right: 15px;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  height: 100%;
  overflow-y: auto;

  nav {
    padding-top: 36px;

    h5.nav__section__header {
      margin-bottom: 15px;
      font-size: 14px;
      font-weight: 600;
      color: #1e293b;
    }

    ul {
      margin-bottom: 24px;
      list-style-type: none;

      &.isMobile {
        li {
          margin-bottom: 16px;
        }
        li a {
          text-decoration: none;
          color: #94a3b8;
          font-size: 14px;
          font-weight: 500;
        }

        display: none;
        background-color: white;

        @include v.tablet {
          display: block;
        }
      }

      li ul {
        position: relative;
        li {
          list-style-type: none;
          margin-top: 8px;

          a {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #475569;
            font-size: 0.875em;
            text-decoration: none;
            word-break: break-word;
            line-height: 1.5;
            padding: 4px 10px;
            gap: 8px;

            span.icon {
              display: flex;
              justify-content: center;
              svg {
                font-size: 1.25em;
              }
            }

            &.active {
              background-color: #fdf2f8;
              border: 1px solid #fbcfe8;
              color: #db2777;
              font-weight: 500;
              position: relative;

              border-radius: 22px;
              span.icon {
                svg {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
  }

  @include v.desktop {
    padding-left: 15px;
    padding-right: 15px;
  }

  @include v.tablet {
    display: none;
    background-color: white;
    margin-top: 62px;

    &.active {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;

      border-right: 0;

      .top__bar {
        height: 80px;
        margin: 0;
        display: flex;
        padding: 0;
        z-index: 20;
        position: relative;
        box-shadow: 0 3px 8px 0 rgba(116, 129, 141, 0.1);
        border-bottom: 1px solid #d4dadf;
        background-color: #ffffff;
      }
    }

    nav {
      position: relative;
      display: block;
      z-index: 999;
      margin-top: 25px;
      .nav__section__header {
        padding: 0px 15px;
      }
    }
  }
}

.dark__background {
  @include v.tablet {
    position: fixed;
    top: 62px;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    opacity: 0;
    pointer-events: none;

    &.my-node-enter-done {
      opacity: 1;
    }

    &.my-node-enter {
      opacity: 0;
    }
    &.my-node-enter-active {
      opacity: 1;
      transition: opacity 200ms;
    }
    &.my-node-exit {
      opacity: 1;
    }
    &.my-node-exit-active {
      opacity: 0;
      transition: opacity 200ms;
    }
  }
}
.login__module {
  padding: 16px;
  background-color: #f8fafc;
  margin-top: 16px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    font-size: 0.875em;
    font-weight: 400;
    color: #475569;
    margin-bottom: 16px;
    line-height: 18px;
  }

  .heading {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    color: #475569;
  }
}
.login__button {
  padding: 20px;

  padding: 12px 16px;
  background-color: black;
  border: 0px;
  border-radius: 6px;
  color: white;
  width: 100%;
  cursor: pointer;

  &:hover {
    background: #10b981;
  }
}
