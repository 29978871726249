@use "../../styles/variables" as v;

header {
  height: fit-content;
  margin: 0;
  padding: 8px;
  position: relative;
  box-shadow: #f8fafc 0px 8px 24px;
  border-bottom: 1px solid #e2e8f0;
  background-color: #ffffff;

  button {
    max-height: 32px;
    background-color: black !important;
    flex-shrink: 0;
  }

  .header__content {
    width: 100%;
    margin: 0 auto;

    .company__logo {
      max-width: 298px;
      width: 100%;
      display: flex;
      align-items: center;
      -webkit-box-pack: center;
      gap: 8px;

      span {
        flex-shrink: 0;
        font-size: 12px;
        font-weight: 500;
        background-color: #fdf2f8;
        border: 1px solid #fbcfe8;
        color: #db2777;
        padding: 6px 8px;
        border-radius: 22px;

        @include v.tablet {
          display: none;
        }
      }

      img {
        max-width: 50px;

        height: auto;
        flex-grow: 0;

        &:nth-child(2) {
          max-width: 75px;
          margin-left: 8px;
        }
      }
    }

    .ham__nav {
      margin-right: 25px;
      position: relative;
      height: 100%;
      width: 18px;
      display: none;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      flex-shrink: 0;

      span {
        transition: all 0.3s ease;
        background-color: rgb(143, 143, 143);
        position: relative;
        height: 2px;
        width: 100%;
        border-radius: 2px;

        &.light {
          background-color: white;
        }

        &.sticky {
          background-color: black !important;
        }

        &:nth-child(1) {
          top: -3px;
        }
        &:nth-child(2) {
          top: 0px;
        }
        &:nth-child(3) {
          top: 3px;
        }
      }

      &.active {
        span {
          transition: all 0.3s ease;
          top: 0;
          background-color: black;

          &:nth-child(1) {
            transform: rotate(45deg);
            top: 1.3px;
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
            top: 0;
          }
        }
      }
    }
  }

  @include v.tablet {
    .header__content {
      justify-content: space-between;
      .company__logo {
        max-width: fit-content;
      }

      .ham__nav {
        display: flex;
      }
    }
  }
}
