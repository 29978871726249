@use "./variables" as v;
@use "../components/navigation/Navigation";
@use "../components/header/header";
@use "../components/views/contentview";
@use "../components/lazyLoading/Lazy";
@use "../components/loading/Loading";
@use "./flexGrid/main.scss";
@use "../components/breadCrumbs/BreadCrumbs";

:root {
  // Backgrounds
  --background-primary: white;
  --background-secondary: #{v.$neutral-50};
  // Headings
  --heading-primary: #0f1728;
  --heading-secondary: #{v.$slate-800};
  // Paras
  --para-primary: #334155;
  // Borders
  --border-primary: #e6e8ed;
  --border-secondary: #{v.$slate-200};
  // Inputs
  --input-background-primary: white;
  --input-placeholder: #{v.$slate-400};
  --input-color: #{v.$slate-900};
  //Links
  --link-inactive: #{v.$slate-600};
  //Hover
  --hover-bg-primary: #{v.$slate-50};
  //table
  --table-head-background: #{v.$gray-100};
  --table-body-background: white;
  --chart-lollipop-background: #eef2ff;
  // buttons
  --button-primary: #fff;
  --button-primary-hover: rgb(249, 250, 251);
  --button-cta-primary: #{v.$emerald-500};
  --button-cta-primary-hover: #{v.$emerald-600};
  --button-icon: #0f1728;
  --button-disabled-background: #{v.$slate-100};
  --button-disabled-color: #{v.$slate-400};
  //icon
  --icon-background-primary: #ddd;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: white;
}

textarea,
select,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
.input-checkbox,
button {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

hr.sep {
  border: none;
  border-top: 1px solid #e2e8f0;
  margin-top: 8px;
  margin-bottom: 16px;
  background-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
span,
small,
button,
input,
tr,
th,
textarea,
.text,
div,
label {
  // font-family: 'Inter', sans-serif;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 0.5%;
  &.bold {
    font-weight: 600;
  }
  &.bolder {
    font-weight: 700;
  }
  &.error {
    color: red;
  }

  i {
    font-style: italic;
  }
}

a {
  text-decoration: none;
}

h1 {
  color: var(--heading-primary);
}

h2 {
  color: var(--heading-primary);
  font-weight: 600;
}

h3 {
  color: var(--heading-primary);
  font-weight: 500;
}

h4 {
  color: var(--heading-primary);
}

h5 {
  color: var(--heading-primary);
}

h6 {
  color: var(--heading-primary);
}

p {
  color: #334155;
  font-size: 15px;
  text-decoration: none;
}

.app__container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  transition: margin-bottom 250ms ease;
  align-items: stretch;
  -moz-transition: margin-bottom 250ms ease;
  -webkit-box-align: stretch;
  -webkit-transition: margin-bottom 250ms ease;
}

.container {
  max-width: 1080px;
}

button {
  background-color: var(--button-primary);
  border: 1px solid var(--border-primary);
  border-radius: 6px;
  color: var(--heading-primary);
  font-size: 0.875rem;
  font-weight: 600;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none #d1d5db solid;
  text-decoration-thickness: auto;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    margin-left: 4px;
    color: var(--button-icon);
  }

  &:hover {
    background-color: var(--button-primary-hover);
  }

  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  &:focus-visible {
    box-shadow: none;
  }

  &:disabled {
    background-color: transparentize($color: v.$slate-600, $amount: 0.88);
    color: var(--heading-primary);
    cursor: not-allowed;
  }

  &.warning {
    background-color: v.$red-500;
    color: white;
    border: 1px solid v.$red-500;
  }

  &.cta {
    background-color: var(--button-cta-primary);
    color: var(--background-primary);
    font-weight: 600;
    border: none !important;
    transition: all 0.2s linear 0s;
    position: relative;

    &:hover {
      background-color: var(--button-cta-primary-hover);
    }

    a {
      color: inherit;
    }

    &:disabled {
      background-color: transparentize($color: v.$slate-600, $amount: 0.7) !important;
      color: v.$slate-600;
      cursor: not-allowed;
    }
  }
}

input,
textarea,
select {
  border-radius: 6px !important;
  border: 1px solid var(--border-primary);
  background-color: var(--input-background-primary);
  color: var(--input-color);
  min-height: 36px;
  font-size: 0.875em;
  padding: 8px 12px;
  width: 100%;

  // Disabled
  &:disabled {
    background-color: var(--background-primary);
    color: #64748b;
    cursor: not-allowed;
  }

  // If value modified
  &.is_modified {
    border: 2px solid v.$emerald-400 !important;

    &:focus {
      outline-color: v.$emerald-400;
    }
  }
}

.overlay__container {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: transparentize($color: black, $amount: 0.8);
  z-index: 9999;
}

.login__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  min-height: 100%;
  position: relative;

  input[type="submit"] {
    background-color: v.$emerald-500;
    color: white;
    border: none;
    cursor: pointer;
  }

  .login__form__container {
    background-color: white;
    border: 1px solid var(--border-primary);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-grow: 0;
    height: auto;
    justify-content: center;
    max-width: 400px;
    padding: 62px 40px;
    width: 100%;
    z-index: 1;

    img {
      max-width: 64px;
      width: 100%;
      height: auto;
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;

    border-radius: 6px;

    .heading {
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        color: #1f2937;
        font-size: 1.6em;
        font-weight: 600;
        margin-bottom: 8px;
      }
    }

    p.error {
      color: red;
      margin-bottom: 24px;
    }

    label {
      display: flex;
      flex-direction: column;
      color: #6b7280;
      letter-spacing: 0.3px;
      font-weight: 500;
      margin-bottom: 24px;
      font-size: 0.875em;

      input {
        margin-top: 6px;
      }
    }
  }
}

.no-wrap {
  flex-wrap: nowrap;
}

.card__style {
  // height: 100%;
  background-color: var(--background-primary);
  // border: 1px solid v.$gray-200;
  border: 1px solid var(--border-primary);
  border-radius: 6px;

  hr.full {
    border: none;
    border-top: 1px solid var(--border-primary);
    // margin: 16px 0px;
    width: 100%;
  }

  @include v.tablet {
    padding: 16px;
  }
}

.anchor {
  @include v.tablet {
    display: none;
  }
}

.loading {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  border: 5px solid black;
  border-left-color: transparent;
  animation: girar infinite linear 1s;

  // @keyframes origin
  //   0%
  //     animation-origin top
  //   25%
  //     animation-origin right
  //   50%
  //     animation-origin bottom
  //   100%
  //     animation-origin left
}

@keyframes girar {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
